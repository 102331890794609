/* eslint-disable max-len */
import { Button, Grid, Input } from '@mui/material';
import React, {
  ChangeEvent,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
// import * as XLSX from 'xlsx';
// import Papa from 'papaparse';
import DataTable from '../../Common/DataTable';
import ComponentDeleteModal from '../../Common/DeleteModal/ComponentDeleteModal';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import { setLoader } from '../../redux/loaderSlice';
import { RootState } from '../../redux/rootState';
import { GetListingPayload } from '../../utils/type';
import {
  getOilReportsStart, uploadExcelStart, removeExcelDetails, deleteOilReportStart,
  resetState,
} from './redux/oilReportSlice';
import { TableColumns } from './utils/constant';
import { DeleteOilReportPayload, OilReportData, UploadExcelPayload } from './utils/types';
import './style.scss';
import ComponentOilReportImportModal from './components/ImportOverview';
import OilReportPdfModal from '../../Common/OilReportPdfModal';
import ComponentOilReportEmailModal from '../../Common/OilReportEmailModal/ComponentOilReportEmailModal';
import { stripCustPrefix } from '../../utils/CommonFunctions';
import { showErrorToaster } from '../../Common/ComponentToast/ComponentSuccessToasts';

export function OilReport() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOilReportEmailModal, setShowOilReportEmailModal] = useState(false);
  const [showOilReportReportModal, setShowOilReportReportModal] = useState(false);
  const [reportNumber, setReportNumber] = useState('');
  const [selectedId, setSelectedId] = useState('');

  const navigate = useNavigate();
  const pdfRef = useRef(null);

  const {
    oilReportList, isLoading, isExcelDataFetched, selectedDocumentId,
    totalOilReports,
    oilReportPdfData,
  } = useSelector((state: RootState) => state.oilReportReducer);

  const dispatch = useDispatch();

  const btnRef = useRef<HTMLButtonElement>(null);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      // const baseName = file.name.substring(0, file.name.lastIndexOf('.'));

      // File format validation
      const allowedExtensions = ['csv', 'xlsx', 'xls'];
      if (!allowedExtensions.includes(fileExtension || '')) {
        showErrorToaster('Invalid file format. Please upload a CSV or Excel file (.csv, .xlsx, .xls).');
        return;
      }

      if (fileExtension === 'csv') {
        // const reader = new FileReader();

        // reader.onload = (e) => {
        //   const csvData = e.target?.result as string;

        //   Papa.parse(csvData, {
        //     header: true,
        //     complete: (result) => {
        //       const jsonData = result.data as Array<Record<string, string>>;

        //       const workbook = XLSX.utils.book_new();
        //       const worksheet = XLSX.utils.json_to_sheet(jsonData);
        //       XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        //       const binaryWorkbook = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        //       const newFile = new File([binaryWorkbook], `${baseName}.xlsx`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Trigger download
        // const blob = new Blob([binaryWorkbook], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        // const url = URL.createObjectURL(blob);
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = `${baseName}.xlsx`;
        // link.click();
        // URL.revokeObjectURL(url);

        //       const payload: UploadExcelPayload = {
        //         file: newFile,
        //       };
        //       dispatch(uploadExcelStart(payload));
        //     },
        //   });
        // };

        // reader.readAsText(file);

        const payload: UploadExcelPayload = {
          file,
        };

        dispatch(uploadExcelStart(payload));
      } else if (fileExtension === 'xlsx' || fileExtension === 'xls') {
        const payload: UploadExcelPayload = {
          file,
        };

        dispatch(uploadExcelStart(payload));
      }
    }

    const inputElement = event.target as HTMLInputElement;
    inputElement.value = ''; // Reset input
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleData = () => {
    const payload: GetListingPayload = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getOilReportsStart(payload));
  };

  useEffect(
    () => (() => {
      dispatch(resetState());
    }),
    [],
  );

  const data: OilReportData[] = useMemo(() => oilReportList
    .map((item, index) => {
      const transformedDataItem = {
        sn: index,
        action: 'edit&delete&sendMail',
        reportNumber: item.ReportNumber,
        kitNumber: item.KitNumber,
        reportDate: item.ReportDate,
        compSerialNumber: item.CompSerialNumber,
        customerNumber: stripCustPrefix(item.CustId),
        location: item.Location,
        serialNumber: item.SerialNumber,
        sampleNumber: item.SampleNumber,
        date: item.Date,
        id: item.ReportNumber,
      };
      return transformedDataItem;
    }), [oilReportList]);

  const handleEdit = (reportId: string) => {
    navigate(`${reportId}`);
  };

  const handleButtonClick = () => {
    btnRef.current?.click();
  };

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
    removeAfterPrint: true,
    copyStyles: true,
  });

  const handleOilReportDelete = () => {
    const payload:DeleteOilReportPayload = {
      reportId: selectedId,
      page,
      rowsPerPage,
      searchQuery,
    };

    dispatch(deleteOilReportStart(payload));
    setShowDeleteModal(false);
  };

  const handleDelete = (id:string) => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const onClickPreview = () => {
    handlePrint();
  };

  const handleOilReportEmailModal = (id: string) => {
    setReportNumber(id);
    setShowOilReportEmailModal(true);
  };

  const getCustomerId = useMemo(() => {
    const reportValues = oilReportList?.find((ele) => ele?.ReportNumber?.toString() === reportNumber?.toString());
    return reportValues?.CustId ?? '';
  }, [reportNumber]);

  const onOilReportModalClose = () => {
    setShowOilReportReportModal(false);
    dispatch(removeExcelDetails());
  };

  useEffect(() => {
    if (selectedDocumentId && isExcelDataFetched && !showOilReportReportModal) {
      setShowOilReportReportModal(true);
    }
  }, [isExcelDataFetched]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading="Oil Report" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <div className="customTableWidth">
            <DataTable<OilReportData>
              handleButtonClick={handleButtonClick}
              data={data}
              columns={TableColumns}
              totalRecords={totalOilReports}
              buttonText="Import"
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleData={handleData}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleOilReportEmailModal={handleOilReportEmailModal}
            />
          </div>
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handleOilReportDelete}
      />
      <ComponentOilReportImportModal
        show={showOilReportReportModal}
        setOpenFrom={onOilReportModalClose}
        rowsPerPage={rowsPerPage}
        page={page}
        searchQuery={searchQuery}
      />
      {getCustomerId && showOilReportEmailModal && (
        <ComponentOilReportEmailModal
          show={showOilReportEmailModal}
          setOpenFrom={() => setShowOilReportEmailModal(false)}
          onClickPreview={onClickPreview}
          customerId={getCustomerId}
          reportNumber={reportNumber ?? ''}
        />
      )}
      <div style={{ display: 'none' }}>
        <div className="d-flex justify-content-center">
          <Input
            type="file"
            inputProps={{ accept: '.csv, .xlsx, .xls', style: { display: 'none' } }}
            id="fileInput"
            onChange={handleChange}
          />
          <label htmlFor="fileInput">
            <Button ref={btnRef} variant="contained" color="success" component="span">
              Import Excel File
            </Button>
          </label>
        </div>
      </div>

      <div style={{ visibility: 'hidden' }}>
        <OilReportPdfModal
          ref={pdfRef}
          oilReportPdfData={oilReportPdfData}
          show
        />
      </div>
    </>
  );
}
