import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { DragIcon } from '../../../../assets/dragIcon';
import { getOrderListSubStart, startOrderingItems } from '../redux/sliceDefectCode';
import Arrow from '../../../../assets/arrowRignt.svg';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';

// Interface for items
export interface Item {
  Id: string;
  Order: string;
  DefectCategory: string;
}

// Helper function to reorder items within a list
const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Function to move an item between two lists
const move = (
  source: Item[],
  destination: Item[],
  droppableSource: { index: number },
  droppableDestination: { index: number },
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);

  return {
    sourceList: sourceClone,
    destList: destClone,
  };
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties | undefined): React.CSSProperties => ({
  userSelect: 'none',
  width: '100%',
  borderRadius: '8px',
  padding: grid * 2,
  border: '1px solid #E9EBEC',
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? '#c9ead9' : '',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? 'white' : 'white',
  padding: grid,
  borderRadius: '5px',
  // width: 250,
  minHeight: 300,
  maxHeight: 600,
  overflow: 'auto',
});

function ComponentDragAndDropList() {
  const { OrderListSub, isLoading } = useSelector((state: RootState) => state.defectCodeReducer);
  const [items, setItems] = useState<Item[]>([]);
  const [selected, setSelected] = useState<Item[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (OrderListSub) {
      const sortedData = OrderListSub
        .slice() // Create a shallow copy to avoid mutating the original array
        .sort((a, b) => Number(a.Order) - Number(b.Order))
        .map((item) => ({ ...item, Id: item?.Id.toString() }));
      setItems(sortedData);
    }
  }, [OrderListSub]);

  const getList = (id: string): Item[] => (id === 'droppable' ? items : selected);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // Dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const reorderedItems = reorder(getList(source?.droppableId), source.index, destination.index);

      if (source.droppableId === 'droppable') {
        setItems(reorderedItems);
      } else {
        setSelected(reorderedItems);
      }
    } else {
      const { sourceList, destList } = move(getList(source?.droppableId), getList(destination?.droppableId), source, destination);

      setItems(source.droppableId === 'droppable' ? sourceList : destList);
      setSelected(destination.droppableId === 'droppable2' ? destList : sourceList);
    }
  };

  const handleGetSelectedData = () => {
    const outputData = selected?.map((item, index) => ({
      Id: Number(item?.Id),
      Order: index + 1,
    }));
    dispatch(startOrderingItems({ data: outputData }));
    setSelected([]);
  };

  useEffect(() => {
    dispatch(getOrderListSubStart());
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <>
      <div className="div_sites_container">
        <div style={{ height: '81vh' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <Droppable droppableId="droppable">
                  {(droppableProvided, droppableSnapshot) => (
                    <>
                      <div className="customerInfo_container" style={{ backgroundColor: '#00a751', borderRadius: '5px' }}>
                        <div style={{ fontSize: '15px', fontWeight: 600, color: 'white' }}>
                          Items
                        </div>
                      </div>
                      <div ref={droppableProvided.innerRef} style={getListStyle(droppableSnapshot.isDraggingOver)}>
                        {items.map((item, index) => (
                          <Draggable key={item.Id} draggableId={item.Id} index={index}>
                            {(draggableProvided, draggableSnapshot) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                              >
                                <div style={{
                                  display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center',
                                }}
                                >
                                  <div style={{
                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                  }}
                                  >
                                    <img src={Arrow} alt="Edit folder" />
                                    <label
                                      className="container_label_draggable"
                                    >
                                      {item.DefectCategory}
                                    </label>
                                  </div>
                                  <DragIcon />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    </>
                  )}
                </Droppable>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Droppable droppableId="droppable2">
                  {(droppableProvided, droppableSnapshot) => (
                    <>
                      <div className="customerInfo_container" style={{ backgroundColor: '#00a751', borderRadius: '5px' }}>
                        <div style={{ fontSize: '15px', fontWeight: 600, color: 'white' }}>
                          Selected Items
                        </div>
                      </div>
                      <div ref={droppableProvided.innerRef} style={getListStyle(droppableSnapshot.isDraggingOver)}>
                        {selected.map((item, index) => (
                          <Draggable key={item.Id} draggableId={item.Id} index={index}>
                            {(draggableProvided, draggableSnapshot) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                style={getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}
                              >
                                <div style={{
                                  display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center',
                                }}
                                >
                                  <div style={{
                                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                                  }}
                                  >
                                    <img src={Arrow} alt="Edit folder" />
                                    <label
                                      className="container_label_draggable"
                                    >
                                      {item.DefectCategory}
                                    </label>
                                  </div>
                                  <DragIcon />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </div>
                    </>
                  )}
                </Droppable>
              </Grid>
            </Grid>
          </DragDropContext>
        </div>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
      >
        <Button
          className="button_save_and_next"
          disabled={items.length !== 0}
          type="button"
          onClick={handleGetSelectedData}
          style={{
            marginTop: '16px',
            marginRight: '16px',
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
}

export default ComponentDragAndDropList;
