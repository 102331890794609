import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  AdditionalMenuItem, DeleteDefectPayload, DeleteResponse, MasterDataResponse, OrderDesDataResponse, TreeDataResponse, UpdateDefectCodeApiResponse,
} from '../utils/TypeAnalystMenu';
import {
  createTreeDataFailure,
  createTreeDataStart,
  createTreeDataSuccess,
  DefectCodeActions, deleteTreeDataFailure, deleteTreeDataStart, deleteTreeDataSuccess,
  getColorDataFailure, getColorDataStart, getColorDataSuccess, getOrderListSubFailure, getOrderListSubStart, getOrderListSubSuccess,
  getTreeDataFailure, getTreeDataStart, getTreeDataSuccess,
  OrderingItemsFailure,
  OrderingItemsSuccess,
  startOrderingItems,
} from './sliceDefectCode';
import Config from '../../../../Common/Config';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../../../../Common/NetworkOps';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';

async function colorMasterData(): Promise<MasterDataResponse> {
  const url = `${Config.defectCode.colorMaster}`;
  const result = await makeGetRequest<MasterDataResponse>(url);
  return result.data;
}

export const getColorMasterDataEpic = (action$: Observable<DefectCodeActions>) => action$.pipe(
  ofType(getColorDataStart.type),
  map((x) => x.payload),
  mergeMap(() => from(colorMasterData()).pipe(
    map((res: MasterDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getColorDataSuccess(res.BMT.Result);
      }
      return getColorDataFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getColorDataStart.match))),
    catchError((error) => of(getColorDataFailure(error))),
  )),
);

async function treeData(): Promise<TreeDataResponse> {
  const url = `${Config.defectCode.treeData}`;
  const result = await makeGetRequest<TreeDataResponse>(url);
  return result.data;
}

export const getTreeDataEpic = (action$: Observable<DefectCodeActions>) => action$.pipe(
  ofType(getTreeDataStart.type),
  map((x) => x.payload),
  debounceTime(300),
  mergeMap(() => from(treeData()).pipe(
    map((res: TreeDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getTreeDataSuccess(res.BMT.Result);
      }
      return getTreeDataFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getTreeDataStart.match))),
    catchError((error) => of(getTreeDataFailure(error))),
  )),
);

async function createDefectCode(data: AdditionalMenuItem): Promise<UpdateDefectCodeApiResponse> {
  const url = `${Config.defectCode.createDefect}`;
  const result = await makePostRequest<UpdateDefectCodeApiResponse>(url, data);
  return result.data;
}

export const epicCreateDefectCode = (action$: Observable<DefectCodeActions>) => action$.pipe(
  filter(createTreeDataStart.match),
  debounceTime(250),
  map((x:DefectCodeActions) => x.payload),
  mergeMap((data) => from(createDefectCode(data as AdditionalMenuItem)).pipe(
    map((res: UpdateDefectCodeApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE || res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return createTreeDataSuccess(res?.BMT?.ResponseMessage);
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return createTreeDataFailure(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(createTreeDataStart.match))),
    catchError((error: AxiosError<UpdateDefectCodeApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      createTreeDataFailure(errorMessage);
      return of();
    }),
  )),
);

async function deleteDefectCode(data: DeleteDefectPayload): Promise<DeleteResponse> {
  const url = `${Config.defectCode.deleteDefect}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteResponse>(url);
  return result.data;
}

export const epicDeleteDefectCode = (action$: Observable<DefectCodeActions>) => action$.pipe(
  filter(deleteTreeDataStart.match),
  debounceTime(250),
  map((x:DefectCodeActions) => x.payload),
  mergeMap((data) => from(deleteDefectCode(data as DeleteDefectPayload)).pipe(
    map((res: DeleteResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE || res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return deleteTreeDataSuccess(res?.BMT?.ResponseMessage);
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return deleteTreeDataFailure(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(deleteTreeDataStart.match))),
    catchError((error: AxiosError<DeleteResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      deleteTreeDataFailure(errorMessage);
      return of();
    }),
  )),
);

async function getOrderList(): Promise<OrderDesDataResponse> {
  const url = `${Config.defectCode.GetOrderListURL}`;
  const result = await makeGetRequest<OrderDesDataResponse>(url);
  return result.data;
}

export const getOrderListSubEpic = (action$: Observable<DefectCodeActions>) => action$.pipe(
  ofType(getOrderListSubStart.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap(() => from(getOrderList()).pipe(
    map((res: OrderDesDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getOrderListSubSuccess(res.BMT.Result);
      }
      return getOrderListSubFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getOrderListSubStart.match))),
    catchError((error) => of(getOrderListSubFailure(error))),
  )),
);

async function OrderingSubAPI(data: { data: object }): Promise<UpdateDefectCodeApiResponse> {
  const url = `${Config.defectCode.OrderingListURL}`;
  const result = await makePostRequest<UpdateDefectCodeApiResponse>(url, data);
  return result.data;
}

export const epicOrderingSub = (action$: Observable<DefectCodeActions>) => action$.pipe(
  filter(startOrderingItems.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: { data: object }) => from(OrderingSubAPI(data)).pipe(
    mergeMap((res: UpdateDefectCodeApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE || res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(OrderingItemsSuccess(res?.BMT?.ResponseMessage), getOrderListSubStart());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(OrderingItemsFailure(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(startOrderingItems.match))),
    catchError((error: AxiosError<UpdateDefectCodeApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      OrderingItemsFailure(errorMessage);
      return of();
    }),
  )),
);
